.event-name {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    background-color: #f0f0f0;
    border-radius: 10px;
    text-align: center;
}

.event-name::before,
.event-name::after {
    content: '🎉';
    /* Unicode character for party popper */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.5em;
}

.event-name::before {
    left: -1.5em;
}

.event-name::after {
    right: -1.5em;
}

/* Media query for screens 600px wide or less */
@media screen and (max-width: 600px) {

    .event-name::before,
    .event-name::after {
        font-size: 1em;
        /* Decrease sticker size */
    }
}